import { U as UnheadSchemaOrg } from './shared/schema-org.CwD-TP0q.mjs';
export { a1 as PluginSchemaOrg, a2 as SchemaOrgUnheadPlugin } from './shared/schema-org.CwD-TP0q.mjs';
import { defineComponent, ref, computed, unref, h } from 'vue';
import { injectHead, useHead } from '@unhead/vue';
import '@unhead/shared';
import 'defu';
import 'ufo';
import 'ohash';

const schemaAutoImports = [
  "defineAddress",
  "defineAggregateOffer",
  "defineAggregateRating",
  "defineArticle",
  "defineBook",
  "defineBookEdition",
  "defineBreadcrumb",
  "defineComment",
  "defineCourse",
  "defineEvent",
  "defineFoodEstablishment",
  "defineHowTo",
  "defineHowToStep",
  "defineImage",
  "defineItemList",
  "defineJobPosting",
  "defineListItem",
  "defineLocalBusiness",
  "defineMovie",
  "defineOffer",
  "defineOpeningHours",
  "defineOrganization",
  "definePerson",
  "definePlace",
  "defineProduct",
  "defineQuestion",
  "defineReadAction",
  "defineRecipe",
  "defineReview",
  "defineSearchAction",
  "defineSoftwareApp",
  "defineVideo",
  "defineVirtualLocation",
  "defineWebPage",
  "defineWebSite",
  "useSchemaOrg"
];
const schemaOrgAutoImports = [
  {
    from: "@unhead/schema-org/vue",
    imports: schemaAutoImports
  }
];
const schemaOrgComponents = [
  "SchemaOrgDebug",
  "SchemaOrgArticle",
  "SchemaOrgBreadcrumb",
  "SchemaOrgComment",
  "SchemaOrgEvent",
  "SchemaOrgFoodEstablishment",
  "SchemaOrgHowTo",
  "SchemaOrgImage",
  "SchemaOrgJobPosting",
  "SchemaOrgLocalBusiness",
  "SchemaOrgOrganization",
  "SchemaOrgPerson",
  "SchemaOrgProduct",
  "SchemaOrgQuestion",
  "SchemaOrgRecipe",
  "SchemaOrgReview",
  "SchemaOrgVideo",
  "SchemaOrgWebPage",
  "SchemaOrgWebSite",
  "SchemaOrgMovie",
  "SchemaOrgCourse",
  "SchemaOrgItemList",
  "SchemaOrgBook",
  "SchemaOrgSoftwareApp"
];
function SchemaOrgResolver(options = {}) {
  const { prefix = "" } = options;
  return {
    type: "component",
    resolve: (name) => {
      if (name.startsWith(prefix)) {
        const componentName = name.substring(prefix.length);
        if (schemaOrgComponents.includes(componentName)) {
          return {
            name: componentName,
            from: "@unhead/schema-org/vue"
          };
        }
      }
    }
  };
}

function shallowVNodesToText(nodes) {
  let text = "";
  for (const node of nodes) {
    if (typeof node.children === "string")
      text += node.children.trim();
  }
  return text;
}
function fixKey(s) {
  let key = s.replace(/-./g, (x) => x[1].toUpperCase());
  if (key === "type" || key === "id")
    key = `@${key}`;
  return key;
}
function ignoreKey(s) {
  if (s.startsWith("aria-") || s.startsWith("data-"))
    return false;
  return s === "class" || s === "style";
}
function defineSchemaOrgComponent(name, defineFn) {
  return defineComponent({
    name,
    props: {
      as: String
    },
    setup(props, { slots, attrs }) {
      const node = ref(null);
      const nodePartial = computed(() => {
        const val = {};
        Object.entries(unref(attrs)).forEach(([key, value]) => {
          if (!ignoreKey(key)) {
            val[fixKey(key)] = unref(value);
          }
        });
        if (!node.value) {
          for (const [key, slot] of Object.entries(slots)) {
            if (!slot || key === "default")
              continue;
            val[fixKey(key)] = shallowVNodesToText(slot(props));
          }
        }
        return val;
      });
      if (defineFn) {
        useSchemaOrg(defineFn(unref(nodePartial)));
      }
      return () => {
        const data = unref(nodePartial);
        if (!slots.default)
          return null;
        const childSlots = [];
        if (slots.default)
          childSlots.push(slots.default(data));
        return h(props.as || "div", {}, childSlots);
      };
    }
  });
}
const SchemaOrgArticle = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgArticle", defineArticle);
const SchemaOrgBreadcrumb = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgBreadcrumb", defineBreadcrumb);
const SchemaOrgComment = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgComment", defineComment);
const SchemaOrgEvent = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgEvent", defineEvent);
const SchemaOrgFoodEstablishment = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgFoodEstablishment", defineFoodEstablishment);
const SchemaOrgHowTo = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgHowTo", defineHowTo);
const SchemaOrgImage = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgImage", defineImage);
const SchemaOrgJobPosting = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgJobPosting", defineJobPosting);
const SchemaOrgLocalBusiness = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgLocalBusiness", defineLocalBusiness);
const SchemaOrgOrganization = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgOrganization", defineOrganization);
const SchemaOrgPerson = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgPerson", definePerson);
const SchemaOrgProduct = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgProduct", defineProduct);
const SchemaOrgQuestion = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgQuestion", defineQuestion);
const SchemaOrgRecipe = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgRecipe", defineRecipe);
const SchemaOrgReview = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgReview", defineReview);
const SchemaOrgVideo = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgVideo", defineVideo);
const SchemaOrgWebPage = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgWebPage", defineWebPage);
const SchemaOrgWebSite = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgWebSite", defineWebSite);
const SchemaOrgMovie = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgMovie", defineMovie);
const SchemaOrgCourse = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgCourse", defineCourse);
const SchemaOrgItemList = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgItemList", defineItemList);
const SchemaOrgBook = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgBook", defineBook);
const SchemaOrgSoftwareApp = /* @__PURE__ */ defineSchemaOrgComponent("SchemaOrgSoftwareApp", defineSoftwareApp);

function provideResolver(input, resolver) {
  if (!input)
    input = {};
  input._resolver = resolver;
  return input;
}
function defineAddress(input) {
  return provideResolver(input, "address");
}
function defineAggregateOffer(input) {
  return provideResolver(input, "aggregateOffer");
}
function defineAggregateRating(input) {
  return provideResolver(input, "aggregateRating");
}
function defineArticle(input) {
  return provideResolver(input, "article");
}
function defineBreadcrumb(input) {
  return provideResolver(input, "breadcrumb");
}
function defineComment(input) {
  return provideResolver(input, "comment");
}
function defineEvent(input) {
  return provideResolver(input, "event");
}
function defineFoodEstablishment(input) {
  return provideResolver(input, "foodEstablishment");
}
function defineVirtualLocation(input) {
  return provideResolver(input, "virtualLocation");
}
function definePlace(input) {
  return provideResolver(input, "place");
}
function defineHowTo(input) {
  return provideResolver(input, "howTo");
}
function defineHowToStep(input) {
  return provideResolver(input, "howToStep");
}
function defineImage(input) {
  return provideResolver(input, "image");
}
function defineJobPosting(input) {
  return provideResolver(input, "jobPosting");
}
function defineLocalBusiness(input) {
  return provideResolver(input, "localBusiness");
}
function defineOffer(input) {
  return provideResolver(input, "offer");
}
function defineOpeningHours(input) {
  return provideResolver(input, "openingHours");
}
function defineOrganization(input) {
  return provideResolver(input, "organization");
}
function definePerson(input) {
  return provideResolver(input, "person");
}
function defineProduct(input) {
  return provideResolver(input, "product");
}
function defineQuestion(input) {
  return provideResolver(input, "question");
}
function defineRecipe(input) {
  return provideResolver(input, "recipe");
}
function defineReview(input) {
  return provideResolver(input, "review");
}
function defineVideo(input) {
  return provideResolver(input, "video");
}
function defineWebPage(input) {
  return provideResolver(input, "webPage");
}
function defineWebSite(input) {
  return provideResolver(input, "webSite");
}
function defineBook(input) {
  return provideResolver(input, "book");
}
function defineCourse(input) {
  return provideResolver(input, "course");
}
function defineItemList(input) {
  return provideResolver(input, "itemList");
}
function defineListItem(input) {
  return provideResolver(input, "listItem");
}
function defineMovie(input) {
  return provideResolver(input, "movie");
}
function defineSearchAction(input) {
  return provideResolver(input, "searchAction");
}
function defineReadAction(input) {
  return provideResolver(input, "readAction");
}
function defineSoftwareApp(input) {
  return provideResolver(input, "softwareApp");
}
function defineBookEdition(input) {
  return provideResolver(input, "bookEdition");
}
function useSchemaOrg(input) {
  const head = injectHead();
  head.use(UnheadSchemaOrg());
  return useHead({
    script: [
      {
        type: "application/ld+json",
        key: "schema-org-graph",
        nodes: input
      }
    ]
  });
}

export { SchemaOrgArticle, SchemaOrgBook, SchemaOrgBreadcrumb, SchemaOrgComment, SchemaOrgCourse, SchemaOrgEvent, SchemaOrgFoodEstablishment, SchemaOrgHowTo, SchemaOrgImage, SchemaOrgItemList, SchemaOrgJobPosting, SchemaOrgLocalBusiness, SchemaOrgMovie, SchemaOrgOrganization, SchemaOrgPerson, SchemaOrgProduct, SchemaOrgQuestion, SchemaOrgRecipe, SchemaOrgResolver, SchemaOrgReview, SchemaOrgSoftwareApp, SchemaOrgVideo, SchemaOrgWebPage, SchemaOrgWebSite, defineAddress, defineAggregateOffer, defineAggregateRating, defineArticle, defineBook, defineBookEdition, defineBreadcrumb, defineComment, defineCourse, defineEvent, defineFoodEstablishment, defineHowTo, defineHowToStep, defineImage, defineItemList, defineJobPosting, defineListItem, defineLocalBusiness, defineMovie, defineOffer, defineOpeningHours, defineOrganization, definePerson, definePlace, defineProduct, defineQuestion, defineReadAction, defineRecipe, defineReview, defineSchemaOrgComponent, defineSearchAction, defineSoftwareApp, defineVideo, defineVirtualLocation, defineWebPage, defineWebSite, schemaAutoImports, schemaOrgAutoImports, schemaOrgComponents, useSchemaOrg };
